import MeritCourseLogo from "../assets/meritcourse-logo.png";
import MeritCourseHero from "../assets/meritcourse-hero.jpeg";

import MeritCloudHero from "../assets/meritcloud-hero.jpg";

import Footer from "./Footer.js";

function MainContainerTemplate() {
  return (
    <div className="main-container">
      <section className="imageblock switchable switchable--switch feature-large bg--light space--sm">
          <div className="imageblock__content col-lg-6 col-md-4 pos-right">
              <div className="background-image-holder">
                  <img alt="MeritCourse Hero" src={MeritCourseHero} />
              </div>
          </div>
          <div className="container">
              <div className="row">
                  <div className="col-lg-5 col-md-7">
                      <h1><img src={MeritCourseLogo} alt="MeritCourse Logo" /></h1>
                      <p className="lead">
                          Join a passionate group of creators who use MeritCourse to monetize their creativity.<br className="hidden-xs hidden-sm" /><br className="hidden-xs hidden-sm" />Easily create an online academy with our all-in-one customizable platform.
                      </p>
                      <div className="modal-instance block">
                          <span>
                          <strong>Launching Soon</strong></span>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section className="imageblock switchable feature-large space--sm bg--secondary">
          <div className="imageblock__content col-lg-6 col-md-4 pos-right">
              <div className="background-image-holder">
                  <img alt="MeritCloud Hero" src={MeritCloudHero} />
              </div>
          </div>
          <div className="container bg--light">
              <div className="row">
                  <div className="col-lg-5 col-md-7">
                      <h2>MeritCloud</h2>
                      <p className="lead">
                          MeritCloud is a blockchain based platform that uses automated machine learning models to aggregate data into a social graph.<br className="hidden-xs hidden-sm" /><br className="hidden-xs hidden-sm" />This social graph is used to produce a reputation score called “MeritScore” for the crypto economy.
                      </p>
                      <div className="modal-instance block">
                          <span>
                          <strong>Coming Q4 2022</strong></span>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section className="text-center bg--light">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <div className="heading-block">
                          <h2>Follow us on Twitter for more updates</h2>
                      </div>
                      <div className="twitterfeed" data-user-name="meritworks_com" data-amount="6" data-grid="6"></div>
                      <a className="btn btn--icon bg--twitter type--uppercase" target="_blank" rel="noreferrer" href="https://twitter.com/meritworks_com">
                          <span className="btn__text">
                              <i className="socicon socicon-twitter"></i>
                              Follow @meritworks_com
                          </span>
                      </a>
                  </div>
              </div>
          </div>
      </section>
      <Footer />
    </div>
  );
}

export default MainContainerTemplate;
