import './App.scss';
import NavContainerTemplate from './templates/NavContainerTemplate.js';
import MainContainerTemplate from './templates/MainContainerTemplate.js';

function App() {
  return (
    <div className="App">
      <NavContainerTemplate />
      <MainContainerTemplate />
    </div>
  );
}

export default App;
