import Logo from '../assets/logo.png';

function NavContainerTemplate() {
  return (
    <div className="nav-container">
      <nav className="bar bar-4 bar--transparent bar--absolute" data-fixed-at="200">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-2 col-md-offset-0 col-4">
              <div className="bar__module">
                <a href="/">
                  <img className="logo logo-dark" alt="logo" src={Logo} />
                  <img className="logo logo-light" alt="logo" src={Logo} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavContainerTemplate;
