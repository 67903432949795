function Footer() {
  return(
    <footer className="text-center space--sm footer-5 bg--dark  ">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="heading-block hidden">
                        <ul className="list-inline list--hover">
                            <li>
                                <a href="/">
                                    <span>Our Company</span>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <span>Locations</span>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <span>Products</span>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <span>Work With Us</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div hidden>
                        <ul className="social-list list-inline list--hover">
                            <li>
                                <a href="/">
                                    <i className="socicon socicon-google icon icon--xs"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <i className="socicon socicon-twitter icon icon--xs"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <i className="socicon socicon-facebook icon icon--xs"></i>
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <i className="socicon socicon-instagram icon icon--xs"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span className="type--fine-print"> &copy; &nbsp;
                        <span className="update-year"></span> MeritWorks LLC.</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
}

export default Footer;
